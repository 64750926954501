<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Add Tag
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-7 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Author"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.author.$model"
                                                    :state="$v.form.author.$dirty ? !$v.form.author.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div> -->
                                    </div>



                                   

                                

                                
                                    <!-- <b-form-group id="example-input-group-1" label="Description"
                                        label-for="example-input-1">
                                        <b-form-textarea id="example-input-1" name="example-input-1"
                                            v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>
                                    </b-form-group> -->

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            image_error2: "",
            image_url2: "",
            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            pdfFile_err: "",
            url: "",
            form: {
                text: null,
                name: null,
                image: "",
                path: null,
                description: null,
                cat_id: null,
                duration: null,
                cover_image: null,
                author: null,
            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
            },
        }
    },

    methods: {

        goBack() {
            let prev_url = '/tags/list';
            this.$router.push({ path: prev_url });
        },

      

    

        async onSubmit() {
            // console.log(this.tags.length);
            // console.log(this.$v.form.description.$error);
            // console.log(this.$v.form.author.$error);
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }
          
                try {
                    this.is_loading = true;
                    let obj = this.$store.getters['getEditFormData'];
                    // console.log('localstorage');
                    //     console.log(localStorage.getItem('obj'));
                    // if(!obj){
                    //     obj = localStorage.getItem('obj');                      
                    // }
                    let endpoint = "/admin/add-tag";

                    // if (obj != "") {
                    //     if (obj.type == "hypnosis") {
                    //         endpoint = "/admin/add-hypnosis";
                    //     }
                    //     else if (obj.type == "meditation") {
                    //         endpoint = "/admin/add-meditation";
                    //     }
                    //     else if (obj.type == "affirmation") {
                    //         endpoint = "/admin/add-affirmation";
                    //     }
                    // }

                    //             formData.append("name", this.vid_name);
                    // formData.append("script_id", this.cat_id);
                    // formData.append("description", this.vid_desc);
                    // formData.append("duration", this.text_duration);
                    // formData.append("author", this.chapter_author);
                    // formData.append("text", this.vid_text);
                    // // formData.append("duration", this.file_duration);
                    // formData.append("image", this.blob);

                    // if(typeof this.tags == 'string'){
                    //   formData.append('tag[]', this.tags);
                    // }else{
                    //   for (var i = 0; i < this.tags.length; i++) {
                    //     formData.append('tag[]', this.tags[i]);
                    //   }
                    // }


                    console.log(obj);
                    const formData = new FormData();
                    formData.append("tag", this.form?.name);
                   

                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    // console.log('after requrest');
                    // console.log(result);
                    this.$store.dispatch('setMessage', { msg });
                    // console.log(this.$root.$refs);
                    // this.showSnotifySuccess(msg)
                    // console.log('cat id', obj.category_id);
                    await this.$store.dispatch(`${obj.type}/all_after_submit`);
                    let prev_url = `/tags/list`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                    // this.progress = 0;
                    // let err = error.response.data.message;
                    // this.$store.dispatch('setMessage', { err });
                    // this.showSnotifyError()
                    // this.$toast.error(this.error);
                }
        },


        showSnotifySuccess(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.success(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        showSnotifyError(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.error(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },

    },




}
</script>